import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import brashears from "../images/team/Brashears.jpg";
import callahan from "../images/team/CathyCallahan.jpg";
import mort from "../images/team/DaveMort.jpg";
import donald from "../images/team/Donald.jpg";
import jamie from "../images/team/JamieAlaniz.jpg";
import jared from "../images/team/JaredGipfert.jpg";
import rebekah from "../images/team/RebekahPhillips.jpg";
import beth from "../images/team/BethHoke.jpg";
import doretta from "../images/team/Doretta.jpg";
import maria from "../images/team/MariaGale.jpg";

function MeetTheTeamPage() {
  return (
    <Layout title="Meet The Team">
      <SEO title="Meet The Team" />

      <div className="md:hidden text-sm text-blue-600 pb-4">
        <Link to="/">&lt; Back to About</Link>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 font-medium">
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={mort} alt="Dave Mort"/>
          <div className="pt-2">Dave Mort</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={brashears} alt="Craig & Victoria Brashears" />
          <div className="pt-2 text-xs">Craig & Victoria Brashears</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={callahan} alt="Cathy Callahan" />
          <div className="pt-2">Cathy Callahan</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={jared} alt="Jared Gipfert" />
          <div className="pt-2">Jared Gipfert</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={jamie} alt="Jamie Alaniz" />
          <div className="pt-2">Jamie Alaniz</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={donald} alt="Donald Froneberger" />
          <div className="pt-2">Donald Froneberger</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={rebekah} alt="Rebekah Phillips" />
          <div className="pt-2">Rebekah Phillips</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={doretta} alt="Doretta Phillips" />
          <div className="pt-2">Doretta Phillips</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={beth} alt="Beth Hoke" />
          <div className="pt-2">Beth Hoke</div>
        </div>
        <div className="border-2 border-black pt-3 px-3 bg-white rounded">
          <img src={maria} alt="Maria Gale" />
          <div className="pt-2">Maria Gale</div>
        </div>
      </div>
    </Layout>
  );
}

export default MeetTheTeamPage;
